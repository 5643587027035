// Notification system: warning, success, info
// -------------------------------------------
.alert {
  background-color: $warning;
  bottom: -100%;
  color: $inverse;
  left: 0;
  opacity: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 8889;
  transition: 0.25s;

  &.is-shown {
    bottom: 0;
    opacity: 1;
  }

  &.is-error {
    background-color: $negative;
  }

  &.is-success {
    background-color: $positive;
  }

  &.is-info {
    background-color: $info;
  }

  &.is-firm {
    background-color: $firm;
  }
}

.alert-container {
  margin: auto;
  padding: 20px;
  position: relative;
  vertical-align: middle;
  max-width: 920px;
  width: 100%;
}

.alert-close {
  color: $inverse;
  cursor: pointer;
  font-size: 24px;
  height: 24px;
  line-height: 1;
  margin-top: -12px;
  opacity: 0.5;
  position: absolute;
  right: 15px;
  top: 50%;
  width: 32px;
  z-index: 2;
  transition: 0.25s;

  &:hover {
    color: $base;
    opacity: 1;
  }
}
