// A set of helpers for each device breakpoint to show/hide a block.
//
// Breakpoints:
// 1. desktop-l: 1536px
// 2. desktop-m: 1366px
// 3. desktop-s: 1024px
// 4. tablet-m: 768px
// 5. mobile-l: 544px
// 6. mobile-m: 375px
// 7. mobile-s: 320px
//
// Shortcuts:
// 1. desktop-all: includes desktop-l, desktop-m, and tablet-s
// 2. mobile-all: includes mobile-l, mobile-m, mobile-s
//
// How to use:
// Syntax: %ACTION%-%DEVICE%-%SIZE%
// Actions: shown, hidden
// Devices: desktop, tablet, mobile
// Sizes: l, m, s, all
//
// Examples:
// 1. Show on desktop (M size): shown-desktop-m
// 2. Hide on all mobile breakpoints: hidden-mobile-all
// 3. Showing starting from tablet-s and above: .hidden-mobile-all

$breakpoints: (
  desktop-all: (
    max: 10000px,
    min: 769px
  ),
  desktop-l: (
    max: 10000px,
    min: 1367px
  ),
  desktop-m: (
    max: 1367px,
    min: 1025px
  ),
  desktop-s: (
    max: 1025px,
    min: 769px
  ),
  tablet-s: (
    max: 769px,
    min: 545px
  ),
  mobile-all: (
    max: 640px,
    min: 1px
  ),
  mobile-l: (
    max: 640px,
    min: 376px
  ),
  mobile-m: (
    max: 376px,
    min: 321px
  ),
  mobile-s: (
    max: 321px,
    min: 1px
  )
);

@each $action in ("shown", "hidden") {
  @each $breakpoint-key in map-keys($breakpoints) {
    $sizes: map-get($breakpoints, $breakpoint-key);
    $selector: unquote("." + $action + "-" + $breakpoint-key);

    #{$selector} {
      @media screen and (max-width: map-get($sizes, max)) and (min-width: map-get($sizes, min)) {
        @if $action == "shown" {
          display: block;
        } @else {
          display: none;
        }
      }

      @media screen and (max-width: map-get($sizes, min)) {
        @if $action == "shown" {
          display: none;
        } @else {
          display: block;
        }
      }

      @media screen and (min-width: map-get($sizes, max)) {
        @if $action == "shown" {
          display: none;
        } @else {
          display: block;
        }
      }
    }
  }
}
