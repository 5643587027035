@include keyframes(teasing) {
  0% {
    opacity: 0;
    top: 20px;
  }

  25% {
    opacity: 1;
    top: 35px;
  }

  50% {
    opacity: 0;
    top: 35px;
  }

  100% {
    opacity: 0;
    top: 35px;
  }
}

@include keyframes(sliding) {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-1895px);
  }
}

// Hero image
// ----------
.hero {
  background-color: rgba(#000, 0.4);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $inverse;
  height: 1000px;
  margin-bottom: -110px;
  position: relative;
  text-align: center;

  &-shadow {
    background: url("~static/hero-shadow.png") 0 0 repeat-x;
    height: 150px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &-content {
    position: relative;
    top: 60px;
    display: inline-block;
    vertical-align: middle;
    z-index: 2;

    &-helper {
      height: 100%;
      display: inline-block;
      vertical-align: middle;
      width: 0;
    }

    @include mq-max-width(480px) {
      top: 80px;
    }

    @include mq-max-width(320px) {
      top: 100px;
    }
  }

  &-cta {
    margin-top: 35px;
  }

  h1 {
    margin-bottom: 0;
    text-shadow: 0 5px 8px rgba(#000, 0.15);

    @include mq-max-width(767px) {
      font-size: 42px;
    }

    @include mq-max-width(640px) {
      font-size: 38px;
    }

    @include mq-max-width(590px) {
      font-size: 28px;
    }

    @include mq-max-width(480px) {
      font-size: 32px;
    }

    @include mq-max-width(320px) {
      font-size: 28px;
    }

    small {
      display: block;
      font-size: 66%;
    }
  }

  .button {
    &.is-hollow {
      &:hover {
        border-color: $inverse;
        color: $inverse;
      }
    }
  }

  @include mq-max-width(767px) {
    height: 610px;
  }
}
