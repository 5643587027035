// reCAPTCHA API verion 2.0
// At present, there is no way to customize the styling of the new reCAPTCHA
// controls, only the wrapper elements around the `iframe` can be stylized.

@media all and (max-width: 480px) {
  .g-recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }
}
