@import "stylesheets/themes/shared/reset";
@import "stylesheets/themes/shared/utils";
@import "stylesheets/themes/shared/colors";

// MODULES
@import "stylesheets/modules/static/alert";
@import "stylesheets/modules/static/button";
@import "stylesheets/modules/static/checkbox";
@import "stylesheets/modules/static/footer";
@import "stylesheets/modules/static/form";
@import "stylesheets/modules/static/header";
@import "stylesheets/modules/static/hero";
@import "stylesheets/modules/static/modal";
@import "stylesheets/plugins/recaptcha.overridden";
@import "stylesheets/modules/icons";
@import "stylesheets/modules/sticky_subnav";
@import "stylesheets/layouts/gdpr_policy";
@import "stylesheets/framework/grids";
@import "stylesheets/framework/mq_utils";
@import "spaces";

@include keyframes(rotating) {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  text-size-adjust: none;

  &.is-cut {
    overflow: hidden;
  }
}

body {
  background-color: #fff;
  color: $base;
  cursor: default;
  font: 14px "aktiv-grotesk", sans-serif;
  line-height: 1.5;
}

a {
  color: $firm;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.25s, font-size 0.1s;

  &:hover,
  &:active {
    color: darken($firm, 10%);
  }
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1c2940;
  font-weight: 700;
}

h1,
.Static-title-xl {
  font-size: 48px;
  line-height: 1.3;
  margin-bottom: 25px;
  -webkit-font-smoothing: antialiased;

  @include mq-max-width(769px) {
    font-size: 36px;
    word-break: break-word;
  }

  @include mq-max-width(545px) {
    font-size: 28px;
  }

  @include mq-max-width(376px) {
    font-size: 24px;
  }
}

h2,
.Static-title-l {
  font-size: 36px;

  @include mq-max-width(769px) {
    font-size: 24px;
  }

  @include mq-max-width(376px) {
    font-size: 20px;
  }
}

h3,
.Static-title-m {
  font-size: 28px;

  @include mq-max-width(769px) {
    font-size: 22px;
  }

  @include mq-max-width(376px) {
    font-size: 18px;
  }
}

h4,
.Static-title-s {
  font-size: 24px;
  font-weight: normal;

  @include mq-max-width(769px) {
    font-size: 20px;
  }

  @include mq-max-width(376px) {
    font-size: 16px;
  }
}

h5,
.Static-title-xs {
  font-size: 20px;
  font-weight: normal;

  @include mq-max-width(769px) {
    font-size: 16px;
  }

  @include mq-max-width(376px) {
    font-size: 14px;
  }
}

h6,
.Static-title-d {
  font-size: 16px;
  font-weight: normal;

  @include mq-max-width(769px) {
    font-size: 14px;
  }

  @include mq-max-width(376px) {
    font-size: 12px;
  }
}

body.is-small-padding {
  max-width: 2160px;
  margin: 0 auto;

  .header {
    max-width: 2160px;
  }

  .Static-container {
    max-width: 2160px;
    margin: 0 auto;

    @include mq-min-width(1920px) {
      padding-left: 160px;
      padding-right: 160px;
    }

    &.is-middle-width {
      max-width: 1400px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.Static {
  &-container {
    margin: auto;
    max-width: 1400px;
    padding-left: 80px;
    padding-right: 80px;

    @include mq-max-width(1340px) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @include mq-max-width(1220px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include mq-max-width(769px) {
      flex-wrap: wrap;
    }

    &-head {
      line-height: 1.7;
      margin: 60px auto;
      max-width: 580px;
      text-align: center;

      @include mq-max-width(545px) {
        margin-bottom: 30px;
        margin-top: 30px;
      }

      &.is-wide {
        max-width: 900px;
      }

      h2 {
        margin-bottom: 20px;
      }

      h6 {
        color: $base;
      }
    }
  }

  &-app {
    opacity: 0;
    transition: opacity 0.5s;
    visibility: hidden;

    &.is-shown {
      opacity: 1;
      visibility: visible;
    }
  }

  &-header-gap {
    padding-top: 100px;
  }

  &-spinner {
    left: 50%;
    opacity: 0;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%) scale(0, 0);
    transition: 0.3s;
    z-index: 9999;

    path {
      opacity: 0;
    }

    &.is-shown {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1, 1);

      path {
        animation: rotating 2s linear infinite;

        &:nth-child(1) {
          animation-delay: 0.4s;
        }

        &:nth-child(2) {
          animation-delay: 0.8s;
        }

        &:nth-child(3) {
          animation-delay: 1.2s;
        }
      }
    }
  }

  &-server-error {
    margin: 20px auto;
    max-width: 640px;
    text-align: center;

    &-holder {
      align-items: center;
      display: flex;
      vertical-align: middle;
    }

    p {
      font-size: 13px;
      margin-bottom: 35px;
    }

    .button {
      min-width: 200px;
    }
  }

  &-content {
    h4 {
      font-size: 125%;
    }

    ul,
    ol {
      list-style-position: inside;
      margin: 0 10px 35px;

      ul,
      ol {
        padding-left: 20px;
      }
    }

    ul {
      list-style-type: disc;

      ul {
        list-style-type: circle;
      }
    }

    ol {
      list-style-type: decimal;
    }

    p {
      margin-bottom: 20px;
    }
  }

  &-nav {
    padding-top: 25px;
  }
}

.text-center {
  text-align: center;
}

.form-row {
  textarea {
    padding-top: 10px;
    min-height: 100px;
    min-width: 100%;
  }
}
