// Buttons
// -------
.button {
  background-color: $firm;
  border: none;
  border-radius: 4px;
  color: $inverse !important;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  line-height: normal;
  padding: 8px 16px 11px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  box-shadow: 0 2px 4px 0 rgb(252 80 30 / 10%), 0 1px 1px 0 rgb(192 43 0 / 30%);
  transition: all 0.25s, font-size 0.1s;

  @include mq-max-width(769px) {
    font-size: 14px;
    padding: 4px 8px 6px;
  }

  &:hover,
  &:focus {
    background-color: scale-color($firm, $lightness: -17%);
    color: $inverse;
  }

  &:active {
    background-color: scale-color($firm, $lightness: -22%);
    transform: translateY(3px) scale(0.98, 0.98);
  }

  &.is-hollow {
    background-color: transparent;
    border-color: $firm;
    color: $firm;

    &:hover,
    &:focus {
      border-color: scale-color($firm, $lightness: -10%);
      color: scale-color($firm, $lightness: -10%);
    }
  }

  &.is-large {
    font-size: 18px;
    padding-bottom: 17px;
    padding-top: 17px;

    @include mq-max-width(769px) {
      font-size: 16px;
      padding: 11px 35px;
    }

    @include mq-max-width(545px) {
      font-size: 14px;
      padding: 7px 12px 8px;
    }
  }

  &.is-block {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  &.is-inverse {
    background-color: $inverse;
    color: $firm;

    &.is-hollow {
      background-color: transparent;
      border-color: $inverse;
      color: $inverse;

      &:hover,
      &:focus {
        border-color: $base;
        color: $base;
      }
    }
  }

  &.is-disabled {
    cursor: default;
    opacity: 0.3;
  }

  .ico {
    margin-top: -2px;
  }

  .deco-arrow {
    margin-left: 20px;
    height: 12px;
    width: 12px;
  }

  &-primary {
    background: #fd6420;
    background: linear-gradient(to bottom, #fd6420, #e03601);
    border-color: #c02b00;
    border-width: 1px;
    border-style: solid;
    padding: 15px;
    box-shadow: none;
    transition: none;

    &:hover {
      background: #fd6c2d;
      background: linear-gradient(to bottom, #fd6c2d, #eb3a02);
      border-color: #cd3002;
    }

    &:active {
      background: #ea3f08;
      border-color: #c02b00;
    }

    &:disabled {
      pointer-events: none;
      cursor: default;
      background: #f8f8f8;
      border-color: #e6e6e6;
      color: #c4c4c4 !important;
      opacity: 1;
    }
  }
}
