// Custom checkbox which does not require any JS
// to function properly.
$self: ".checkbox";

#{$self} {
  input {
    left: -9999px;
    position: absolute;

    & + label {
      cursor: pointer;
      padding-left: 28px;
      position: relative;

      // Checkbox shape
      &::before {
        background-color: #fff;
        border: 2px solid rgba(#000, 0.5);
        content: "";
        height: 20px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
      }

      // Checked mark
      &::after {
        background: url("~static/checkbox/checkmark.png") no-repeat left center;
        background-size: contain;
        content: "";
        height: 12px;
        left: 3px;
        opacity: 0;
        position: absolute;
        top: 3px;
        transform: scale(0);
        transition: 0.2s;
        width: 14px;
      }
    }

    &:checked {
      & + label::after {
        opacity: 1;
        transform: scale(1);
      }
    }

    &:disabled + label {
      cursor: default;

      &::before {
        background-color: rgba(#000, 0.01);
        border-color: rgba(#000, 0.05);
      }

      &::after {
        opacity: 0.25;
      }
    }

    &:not(:disabled) {
      & + label:hover::before {
        border-color: #4778d9 !important;
      }

      &:focus + label::before {
        border: 2px dotted rgba(#000, 0.5);
      }
    }
  }

  &-kit input {
    & + label {
      font-size: 14px;

      &::before {
        border: 1px solid #ccc;
        box-shadow: inset 0 1px 2px 0 rgb(0 0 0 / 10%);
      }
    }

    &:checked + label::before {
      background: #f9f0cd;
      border-color: #c8bd8e;
    }
  }
}
