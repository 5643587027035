@import "stylesheets/application_v2/fonts_config";

.footer {
  border-top: 1px solid #e8ebef;
  border-bottom: 1px solid #e8ebef;
  padding: 50px 0 84px;
  background-color: #fff;

  @include mq-max-width(640px) {
    padding-top: 20px;
  }
}

.footer-container {
  display: grid;
  grid-template-columns: 144px auto 144px;
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-areas: "logo navigation social";

  @include mq-max-width(640px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: "navigation navigation" "logo social";
  }
}

.footer-nav-container {
  @include mq-max-width(1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @include mq-max-width(640px) {
    grid-template-columns: auto;
    grid-row-gap: 16px;
  }

  & {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-area: navigation;
  }
}

.footer-nav-column {
  @include mq-max-width(640px) {
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #e8ebef;
      opacity: 0.5;
      margin-top: 16px;
    }

    &:not(.is-open) {
      .footer-nav-links-list {
        display: none;
      }

      .footer-nav-column-title-arrow-icon {
        transform: rotate(180deg);
      }
    }
  }
}

.footer-nav-links-list {
  @include mq-max-width(640px) {
    margin-top: 16px;
  }
}

.footer-nav-column-title {
  margin-bottom: 8px;
  color: #1c2940;
  font-family: $aktiv-grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  svg {
    @include mq-min-width(641px) {
      display: none;
    }
  }
}

.footer-nav-link-container {
  margin-bottom: 8px;
}

.footer-nav-link {
  color: #1c2940;
  font-family: $aktiv-grotesk;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 22px;
}

.footer-socials-list {
  display: flex;
  margin: -8px;
}

.footer-socials-items-container {
  margin: 8px;
}

.footer-logo-container {
  grid-area: logo;

  img {
    margin-bottom: 16px;
  }
}

.footer-socials-container {
  @include mq-max-width(640px) {
    flex-direction: column;
    justify-content: flex-end;
  }

  & {
    grid-area: social;
    display: flex;
  }
}

.footer-office-list {
  padding-bottom: 100px;
  padding-top: 50px;
  background: #fff;

  @include mq-max-width(640px) {
    padding-bottom: 30px;
  }

  .Static-container {
    display: flex;
    justify-content: center;

    @include mq-max-width(640px) {
      display: block;
    }
  }
}

.footer-office {
  display: flex;
  align-items: center;
  color: #40506c;
  margin-right: 80px;

  @include mq-max-width(640px) {
    margin-right: 0;
    margin-bottom: 20px;

    .footer-office-icon-link {
      display: none;
    }
  }
}

.footer-office-icon-link {
  margin-right: 16px;

  img {
    transform: translateY(4px);
  }
}

.footer-office-title {
  margin-bottom: 8px;
  color: #1c2940;
  font-family: $aktiv-grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
}

.footer-office-address {
  color: #8b97a9;
  font-family: $aktiv-grotesk;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 18px;
}
