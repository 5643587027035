@import "stylesheets/application_v2/config";

[class^="icon-"],
[class*=" icon-"] {
  span {
    opacity: 0;
  }
}

.ico {
  position: relative;
  vertical-align: middle;
  display: inline-block;
}

.ico-flash {
  width: 26px;
  height: 26px;
  line-height: 26px;
  background:
    url("~application_v2/icon-flash-26x26.png") no-repeat center
    center;
}

.ico-email-capture {
  width: 30px;
  height: 22px;
  line-height: 26px;
  margin-top: -4px;
  background:
    url("~application_v2/icon-email-capture-popup-30x22.png")
    no-repeat center center;

  @include retina {
    background-image: url("~application_v2/icon-email-capture-popup-30x22-2x.png");
  }
}

.ico-leaderboard {
  width: 32px;
  height: 31px;
  line-height: 26px;
  background:
    url("~application_v2/icon-leaderboard-32x31.png") no-repeat center
    center;
  background-size: 32px 31px;

  @include retina {
    background-image: url("~application_v2/icon-leaderboard-32x31-2x.png");
  }
}

.ico-cart {
  width: 32px;
  height: 23px;
  line-height: 23px;
  background: url("~application_v2/icon-cart-32x23.png") no-repeat center center;
}

.ico-star {
  width: 33px;
  height: 27px;
  line-height: 27px;
  background: url("~application_v2/icon-star-33x27.png") no-repeat center center;
}

.ico-speed {
  width: 30px;
  height: 20px;
  line-height: 20px;
  background:
    url("~application_v2/icon-speed-30x20.png") no-repeat center
    center;
}

.ico-switch {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background:
    url("~application_v2/icon-switch-60x60.png") no-repeat center
    center;
}

.ico-schedule {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background:
    url("~application_v2/icon-schedule-60x60.png") no-repeat center
    center;
}

.ico-settings {
  width: 60px;
  height: 44px;
  line-height: 44px;
  background:
    url("~application_v2/icon-settings-60x44.png") no-repeat center
    center;
}

.ico-radar {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background:
    url("~application_v2/icon-radar-60x60.png") no-repeat center
    center;
}

.ico-piechart {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background:
    url("~application_v2/icon-piechart-60x60.png") no-repeat center
    center;
}

.ico-arrow {
  width: 16px;
  height: 26px;
  line-height: 43px;
  background:
    url("~application_v2/icon-arrows-16x26.png") no-repeat center
    center;

  &.is-left {
    background-position: 0 0;

    &:hover {
      background-position: 0 -46px;
    }
  }

  &.is-right {
    background-position: 0 -92px;

    &:hover {
      background-position: 0 -138px;
    }
  }
}

.ico-arrow-right {
  margin-left: 7px;
  width: 16px;
  height: 13px;
  line-height: 13px;
  background: url("~static/icon-arrow-right-16x13.png") no-repeat center center;
}

.ico-mail {
  width: 17px;
  height: 13px;
  line-height: 13px;
  background: url("~application_v2/icon-email.svg") no-repeat center center;
  background-size: contain;

  &.is-gray {
    background:
      url("~application_v2/icon-email-gray.svg") no-repeat center
      center;
  }
}

.ico-web-view {
  width: 15px;
  height: 15px;
  line-height: 15px;
  background: url("~application_v2/icon-web-view.svg") no-repeat center center;
  background-size: contain;

  &.is-gray {
    background:
      url("~application_v2/icon-web-view-gray.svg") no-repeat center
      center;
  }
}

.ico-arrowhead-down {
  width: 11px;
  height: 7px;
  line-height: 7px;
  background:
    url("~application_v2/icon-arrowhead-down.svg") no-repeat center
    center;
  background-size: contain;
}

.ico-link {
  width: 52px;
  height: 52px;
  line-height: 52px;
  background: url("~application_v2/icon-link-52x52.png") no-repeat center center;
}

.ico-copy {
  width: 14px;
  height: 16px;
  line-height: 16px;
  background: url("~application_v2/icon-copy-14x16.png") no-repeat center center;
}

.svg {
  &-holder {
    &:hover,
    &.hover {
      &:not(.is-inactive) {
        .sign-svg {
          &-gear,
          &-drop,
          &-text,
          &-image,
          &-redirect {
            .svg-fill {
              fill: #ff4e00;
            }
          }

          &-refresh {
            .sign-svg-fill {
              transition: 0.3s;
              fill: $link-color;
            }
          }
        }
      }

      .icon-svg {
        &-eye {
          .svg-border,
          .sign-svg-border {
            opacity: 1;
          }
        }

        &-gear {
          .svg-fill {
            fill: #ff4e00;
          }
        }

        &-redirect {
          .svg-shape {
            fill: #ff4e00;
          }
        }

        &-file {
          .svg-shape {
            fill: #f60;
          }
        }

        &-round-link {
          .svg-shape {
            stroke: #f60;
          }
        }

        &-winner {
          .svg-stroke {
            stroke: darken(#0070d9, 15%);
          }

          .svg-fill {
            fill: darken(#0070d9, 15%);
          }
        }
      }
    }

    &.is-active {
      .sign-svg {
        &-text,
        &-image,
        &-gear,
        &-drop {
          .svg-fill {
            fill: #000;
          }
        }
      }
    }

    &.is-disabled {
      .icon-svg {
        &-eye {
          cursor: not-allowed;

          .svg-dot,
          .svg-shape,
          .svg-border {
            fill: #e1e1e1;
            opacity: 1;
          }
        }
      }
    }
  }
}

.sign-svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;

  &.is-right {
    margin-left: 7px;
    margin-right: 0;
  }

  &.is-center {
    margin: 0 auto;
  }

  &:not(.is-base-colored) {
    .sign-svg-border {
      fill: #b0cee1;
    }

    .sign-svg-main {
      fill: #0070d9;
    }
  }

  .sign-svg-link-colors-parent & {
    fill: #0070d9;
  }

  &-border,
  &-main,
  &-fill,
  &-stroke,
  &-canvas-stroke,
  &-canvas-fill,
  .sign-svg-link-colors-parent & {
    transition: 0.3s;
  }

  .sign-svg-link-colors-parent:hover &,
  &.is-hover:not(.is-disabled):hover {
    cursor: pointer;
    fill: #ff4e00;

    .sign-svg-border,
    .sign-svg-main {
      fill: #ff4e00;
    }
  }

  &-bin {
    position: relative;
    top: -1px;
  }

  * {
    pointer-events: none;
  }
}

.is-disabled {
  .sign-svg {
    &-main {
      fill: #ccc;
    }

    &-border {
      fill: #e6e6e6;
    }

    &-stroke {
      stroke: #c4c4c4;
    }
  }
}

[class^="icon-svg"],
[class*=" icon-svg"] {
  margin-top: 0;
  width: 24px;
  height: 24px;
  background: none;
  background-image: none !important;
  vertical-align: middle;
}

.icon {
  &-holder {
    &:hover {
      .icon-dots {
        background-color: #0070d9;

        &::after,
        &::before {
          background-color: #0070d9;
        }
      }
    }
  }

  &.icon {
    &-dots,
    &-dots::before,
    &-dots::after {
      background-color: #858585;
      border-radius: 50%;
      display: inline-block;
      height: 4px;
      width: 4px;
      transition: 0.3s;
    }

    &-dots::before,
    &-dots::after {
      position: absolute;
      content: "";
      left: -7px;
    }

    &-dots::after {
      left: 7px;
    }

    &-dots {
      position: relative;
    }

    &-add-variant {
      background-image: none;
      border: 2px solid rgba($link-color, 0.3);
      border-radius: 50%;
      display: inline-block;
      height: 20px;
      margin-top: 0;
      position: relative;
      vertical-align: middle;
      width: 20px;
      transition: 0.3s;

      &::before,
      &::after {
        $size: 12px;

        background-color: $link-color;
        content: "";
        height: 2px;
        left: 50%;
        margin: -1px 0 0 -6px;
        position: absolute;
        top: 50%;
        width: $size;
        transition: 0.3s;
      }

      &::after {
        transform: rotate(90deg);
      }

      &:hover {
        color: $firm;

        i {
          border-color: $firm;

          &::before,
          &::after {
            background-color: $firm;
          }
        }
      }
    }

    &-mini-preview {
      background:
        url("~application_v2/layouts/routes/icon-mini-preview.png")
        center center no-repeat;
      background-size: 14px 10px;
      border: 2px solid rgba($link-color, 0.3);
      border-radius: 50%;
      cursor: pointer;
      display: inline-block;
      height: 20px;
      margin-left: 8px;
      margin-top: -1px;
      vertical-align: middle;
      transition: 0.3s;
      width: 20px;

      @include retina {
        background-image: url("~application_v2/layouts/routes/icon-mini-preview-2x.png");
      }

      &:hover {
        border-color: $link-color;
      }

      &.is-disabled {
        background-image: url("~application_v2/layouts/routes/icon-mini-preview-disabled.png");
        border-color: rgba(#000, 0.1);
        cursor: not-allowed;

        @include retina {
          background-image: url("~application_v2/layouts/routes/icon-mini-preview-disabled-2x.png");
        }
      }
    }

    &-stop-sign {
      background:
        url("~application_v2/icon-stop-sign-238x204.png") 0 0
        no-repeat;
      width: 238px;
      height: 204px;

      @include retina {
        background-image: url("~application_v2/icon-stop-sign-238x204@2x.png");
        background-size: 238px 204px;
      }
    }

    &-page-type {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #f60;

      &.is-inverse {
        background: #a16ee9;
      }
    }

    &-bar {
      display: block;
      width: 16px;
      background: #818181;
      height: 2px;
      margin-top: 3px;

      &-circles {
        width: 16px;
        height: 16px;
        background: none;
      }

      &-round-plus {
        width: 24px;
        height: 24px;
        background: none;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    &-cross-styled {
      width: 44px;
      height: 44px;

      &.is-error {
        background: url("~application_v2/icon-cross-error.svg") 0 0 no-repeat;
      }

      &.is-success {
        background: url("~application_v2/icon-cross-success.svg") 0 0 no-repeat;
      }
    }

    &-svg {
      &-lock {
        &-blank {
          width: 24px;
          height: 24px;
        }

        &-filled {
          width: 16px;
          height: 16px;
        }
      }

      &-alert {
        width: 40px;
        height: 40px;

        &-filled {
          width: 16px;
          height: 16px;
        }

        &-circle {
          &-filled {
            width: 16px;
            height: 16px;
          }
        }
      }

      &-triangle-warning {
        width: 16px;
        height: 16px;
      }

      &-eye {
        width: 24px;
        height: 24px;

        .svg-border,
        .sign-svg-border {
          opacity: 0.3;
        }
      }

      &-round-link {
        width: 26px;
        height: 26px;
      }

      &-gear,
      &-redirect {
        width: 20px;
        height: 20px;
        background: none;

        .svg-shape {
          fill: #0070d9;
        }
      }

      &-file {
        height: 18px;
        width: 16px;

        .svg-shape {
          fill: #0070d9;
        }
      }

      &-redirect {
        .svg-shape {
          fill: #0070d9;
        }
      }

      &-cross {
        width: 12px;
        height: 12px;
        margin-top: 0;
        fill: #999;
      }
    }

    &-collapse {
      background: url("~application_v2/icon-collapse-16x16.png") 0 0 no-repeat;
      width: 16px;
      height: 16px;

      @include retina {
        background-image: url("~application_v2/icon-collapse-16x16@2x.png");
        background-size: 16px 16px;
      }
    }

    &-success-tick {
      width: 12px;
      height: 12px;
      background:
        url("~application_v2/campaign_details/icon-success-tick.svg")
        no-repeat center center;
      background-size: contain;
    }

    &-waiting {
      width: 12px;
      height: 12px;
      background:
        url("~application_v2/campaign_details/icon-waiting.svg")
        no-repeat center center;
      background-size: contain;
    }

    &-blocked {
      width: 12px;
      height: 12px;
      background:
        url("~application_v2/campaign_details/icon-blocked.svg")
        no-repeat center center;
      background-size: contain;
    }

    &-flagged {
      width: 11px;
      height: 12px;
      background:
        url("~application_v2/campaign_details/icon-flagged.svg")
        no-repeat center center;
      background-size: contain;
    }

    &-spinner {
      width: 60px;
      height: 60px;
      background:
        url("~application_v2/campaign_details/spinner.gif") no-repeat
        center center;
    }

    &-caution {
      position: relative;
      top: -2px;
      width: 22px;
      height: 19px;
      background: url("~application_v2/icon-caution.png") 0 0 no-repeat;
    }

    &-caution-circle {
      position: relative;
      width: 16px;
      height: 16px;
      background: url("~application_v2/icon-caution-circle.png") 0 0 no-repeat;
    }

    &-file {
      position: relative;
      width: 16px;
      height: 18px;
      background:
        url("~application_v2/campaign_details/icon-file-16x18.png") 0
        0 no-repeat;

      @include retina {
        background-image: url("~application_v2/campaign_details/icon-file-16x18@2x.png");
        background-size: 16px 18px;
      }
    }

    &-gray-arrow-right {
      position: relative;
      width: 30px;
      height: 12px;
      background:
        url("~application_v2/campaign_details/icon-arrow-right-30x12.png")
        0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/campaign_details/icon-arrow-right-30x12@2x.png");
        background-size: 30px 12px;
      }
    }

    &-info-blue {
      position: relative;
      width: 16px;
      height: 16px;
      background: url("~application_v2/icon-info-blue.png") 0 0 no-repeat;
    }

    &-question {
      width: 16px;
      height: 16px;
      line-height: 16px;
      background:
        url("~application_v2/icon-circle-question-16x16.png") 0 0
        no-repeat;
      margin-top: 0;

      &.is-m {
        width: 20px;
        height: 20px;
        background:
          url("~application_v2/icon-circle-question-20x20.png") 0 0
          no-repeat;

        @include retina {
          background-image: url("~application_v2/icon-circle-question-20x20@2x.png");
          background-size: 20px 20px;
        }
      }

      &.is-middle {
        vertical-align: middle;
      }

      &.is-mls {
        margin-left: 5px;
      }
    }

    &-arrow-up-down {
      width: 9px;
      height: 16px;
      line-height: 16px;
      background:
        url("~application_v2/icon-arrow-up-down-9x16.png") 0 0
        no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-arrow-up-down-9x16@2x.png");
        background-size: 9px 16px;
      }

      .is-disabled & {
        opacity: 0.5;
      }
    }

    &-brackets {
      opacity: 0.6;
      width: 24px;
      height: 24px;
      line-height: 24px;
      background: url("~application_v2/icon-brackets-24x24.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-brackets-24x24@2x.png");
        background-size: 24px 24px;
      }

      .is-active & {
        opacity: 1;
      }
    }

    &-folder {
      opacity: 0.6;
      width: 24px;
      height: 20px;
      line-height: 20px;
      background: url("~application_v2/icon-folder-24x20.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-folder-24x20@2x.png");
        background-size: 24px 20px;
      }

      .is-active & {
        opacity: 1;
      }
    }

    &-large-speed {
      width: 77px;
      height: 53px;
      line-height: 53px;
      background:
        url("~application_v2/campaign_new/icon-speed-77x53.png")
        no-repeat center center;

      @include retina {
        background-image: url("~application_v2/campaign_new/icon-speed-77x53@2x.png");
        background-size: 77px 53px;
      }
    }

    &-large-star {
      width: 70px;
      height: 70px;
      line-height: 70px;
      background:
        url("~application_v2/campaign_new/icon-star-70x70.png")
        no-repeat center center;

      @include retina {
        background-image: url("~application_v2/campaign_new/icon-star-70x70@2x.png");
        background-size: 70px 70px;
      }
    }

    &-lights {
      width: 67px;
      height: 67px;
      line-height: 67px;
      background:
        url("~application_v2/campaign_new/icon-lights-67x67.png")
        no-repeat center center;

      @include retina {
        background-image: url("~application_v2/campaign_new/icon-lights-67x67@2x.png");
        background-size: 67px 67px;
      }
    }

    &-lights-small {
      width: 32px;
      height: 32px;
      line-height: 32px;
      background:
        url("~application_v2/campaign_new/icon-lights-small-32x32.png")
        no-repeat center center;

      @include retina {
        background-image: url("~application_v2/campaign_new/icon-lights-small-32x32@2x.png");
        background-size: 32px 32px;
      }
    }

    &-large-cart {
      width: 83px;
      height: 62px;
      line-height: 62px;
      background:
        url("~application_v2/campaign_new/icon-cart-83x62.png")
        no-repeat center center;

      @include retina {
        background-image: url("~application_v2/campaign_new/icon-cart-83x62@2x.png");
        background-size: 83px 62px;
      }
    }

    &-ticket {
      width: 80px;
      height: 60px;
      line-height: 60px;
      background:
        url("~application_v2/campaign_new/icon-ticket-80x60.png")
        no-repeat center center;

      @include retina {
        background-image: url("~application_v2/campaign_new/icon-ticket-80x60@2x.png");
        background-size: 80px 60px;
      }
    }

    &-ticket-small {
      width: 30px;
      height: 23px;
      line-height: 23px;
      background:
        url("~application_v2/campaign_new/icon-ticket-small-30x23.png")
        no-repeat center center;

      @include retina {
        background-image: url("~application_v2/campaign_new/icon-ticket-small-30x23@2x.png");
        background-size: 30px 23px;
      }
    }

    &-history {
      opacity: 0.6;
      width: 22px;
      height: 22px;
      line-height: 22px;
      background: url("~application_v2/icon-history-22x22.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-history-22x22@2x.png");
        background-size: 22px 22px;
      }

      .is-active & {
        opacity: 1;
      }
    }

    &-open {
      width: 13px;
      height: 13px;
      line-height: 13px;
      background:
        url("~application_v2/icon-square-arrow-13x13.png") 0 0
        no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-square-arrow-13x13@2x.png");
        background-size: 13px 13px;
      }
    }

    &-settings {
      opacity: 0.6;
      width: 24px;
      height: 24px;
      line-height: 24px;
      background: url("~application_v2/icon-settings-24x24.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-settings-24x24@2x.png");
        background-size: 24px 24px;
      }

      .is-active & {
        opacity: 1;
      }
    }

    &-round-settings {
      opacity: 1;
      width: 20px;
      height: 18px;
      background: url("~application_v2/icon-settings.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-settings@2x.png");
        background-size: 20px 18px;
      }
    }

    &-tick {
      width: 13px;
      height: 10px;
      line-height: 10px;
      background: url("~application_v2/icon-tick-13x10.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-tick-13x10@2x.png");
        background-size: 13px 10px;
      }
    }

    &-rocket {
      opacity: 0.6;
      width: 18px;
      height: 22px;
      line-height: 22px;
      background: url("~application_v2/icon-rocket-18x22.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-rocket-18x22@2x.png");
        background-size: 18px 22px;
      }

      .is-active & {
        opacity: 1;
      }
    }

    &-docs {
      width: 24px;
      height: 20px;
      line-height: 20px;
      background: url("~application_v2/icon-docs-24x20.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-docs-24x20@2x.png");
        background-size: 24px 20px;
      }
    }

    &-question-large {
      width: 20px;
      height: 20px;
      background:
        url("~application_v2/icon-circle-question-20x20.png") 0 0
        no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-circle-question-20x20@2x.png");
        background-size: 20px 20px;
      }
    }

    &-search {
      width: 21px;
      height: 22px;
      background:
        url("~application_v2/icon-search-white-21x22.png") 0 0
        no-repeat;
    }

    &-cash {
      width: 68px;
      height: 51px;
      background: url("~application_v2/icon-cash-68x51.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-cash-68x51@2x.png");
        background-size: 68px 51px;
      }
    }

    &-shares {
      width: 54px;
      height: 53px;
      background: url("~application_v2/icon-shares-54x53.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-shares-54x53@2x.png");
        background-size: 54px 53px;
      }
    }

    &-type-email {
      width: 17px;
      height: 13px;
      background:
        url("~application_v2/icon-type-email-17x13.png") center center
        no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-type-email-17x13@2x.png");
        background-size: 17px 13px;
      }

      &.is-selected {
        background:
          url("~application_v2/icon-type-email-orange-17x13.png")
          center center no-repeat;

        @include retina {
          background-image: url("~application_v2/icon-type-email-orange-17x13@2x.png");
          background-size: 17px 13px;
        }
      }
    }

    &-type-active-email {
      width: 17px;
      height: 13px;
      background:
        url("~application_v2/icon-type-active-email-17x13.png") center
        center no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-type-active-email-17x13@2x.png");
        background-size: 17px 13px;
      }
    }

    &-type-page {
      width: 15px;
      height: 15px;
      background:
        url("~application_v2/icon-type-page-15x15.png") center center
        no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-type-page-15x15@2x.png");
        background-size: 15px 15px;
      }

      &.is-selected {
        background:
          url("~application_v2/icon-type-active-page-15x15.png")
          center center no-repeat;

        @include retina {
          background-image: url("~application_v2/icon-type-active-page-15x15@2x.png");
          background-size: 15px 15px;
        }
      }
    }

    &-type-active-page {
      width: 15px;
      height: 15px;
      background:
        url("~application_v2/icon-type-active-page-15x15.png") center
        center no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-type-active-page-15x15@2x.png");
        background-size: 15px 15px;
      }
    }

    &-plus-light {
      width: 12px;
      height: 12px;
      background:
        url("~application_v2/icon-plus-12x12.png") center center
        no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-plus-12x12@2x.png");
        background-size: 12px 12px;
      }
    }

    &-plus-dark {
      width: 12px;
      height: 12px;
      background:
        url("~application_v2/icon-plus-dark-12x12.png") center center
        no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-plus-dark-12x12@2x.png");
        background-size: 12px 12px;
      }
    }

    &-upload {
      width: 20px;
      height: 15px;
      background: url("~application_v2/icon-upload-20x15.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-upload-20x15@2x.png");
        background-size: 20px 15px;
      }
    }

    &-trash {
      width: 14px;
      height: 14px;
      background: url("~application_v2/icon-trash-14x14.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-trash-14x14@2x.png");
        background-size: 14px 14px;
      }

      &:hover {
        background:
          url("~application_v2/icon-trash-red-14x14.png") 0 0
          no-repeat;

        @include retina {
          background-image: url("~application_v2/icon-trash-red-14x14@2x.png");
          background-size: 14px 14px;
        }
      }
    }

    &-trash-white {
      width: 14px;
      height: 14px;
      background:
        url("~application_v2/icon-trash-white-14x14.png") 0 0
        no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-trash-white-14x14@2x.png");
        background-size: 14px 14px;
      }
    }

    &-copy {
      width: 14px;
      height: 14px;
      background: url("~application_v2/icon-copy-14x14.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-copy-14x14@2x.png");
        background-size: 14px 14px;
      }
    }

    &-copy-active {
      width: 14px;
      height: 14px;
      background:
        url("~application_v2/icon-copy-14x14-active.png") 0 0
        no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-copy-14x14-active@2x.png");
        background-size: 14px 14px;
      }
    }

    &-paper-plane-firm {
      width: 39px;
      height: 39px;
      background:
        url("~application_v2/icon-paper-plane-firm-39x39.png") 0 0
        no-repeat;
    }

    &-cash-firm {
      width: 49px;
      height: 37px;
      background: url("~application_v2/icon-cash-firm-49x37.png") 0 0 no-repeat;
    }

    &-heart-firm {
      width: 49px;
      height: 39px;
      background: url("~application_v2/icon-heart-firm-49x39.png") 0 0 no-repeat;
    }

    &-dollar-dotted-circle-firm {
      width: 45px;
      height: 45px;
      background:
        url("~application_v2/icon-dollar-dotted-circle-firm-45x45.png")
        0 0 no-repeat;
    }

    &-circle-profile {
      width: 24px;
      height: 24px;
      background:
        url("~application_v2/icon-circle-profile-24x24.png") 0 0
        no-repeat;
    }

    &-white-x {
      width: 15px;
      height: 15px;
      background:
        url("~application_v2/icon-white-x-remove-15x15.png") 0 0
        no-repeat;
    }

    &-white-ok {
      width: 20px;
      height: 15px;
      background:
        url("~application_v2/icon-white-ok-check-20x15.png") 0 0
        no-repeat;
    }

    &-small-star {
      width: 13px;
      height: 13px;
      background: url("~application_v2/icon-star-small-13x13.png") 0 0 no-repeat;
    }

    &-small-check-mark {
      width: 12px;
      height: 9px;
      background:
        url("~application_v2/icon-small-green-check-mark-12x9.png") 0
        0 no-repeat;
    }

    &-plus-inverse {
      background-image: url("~application_v2/icon-plus-white.png");
      background-position: 0 0;
      background-size: 14px 14px;
      margin-right: 5px;
      position: relative;
      vertical-align: middle;
      top: -1px;

      @include retina {
        background-image: url("~application_v2/icon-plus-white@2x.png");
      }
    }

    &-cross {
      position: relative;
      width: 12px;
      height: 3px;

      &::before,
      &::after {
        display: inline-block;
        transform-origin: 50% 50%;
        content: "";
        width: 12px;
        height: 3px;
        position: absolute;
        left: 0;
        top: 0;
        background: #ce0000;
      }

      &::before {
        transform: rotate3d(0, 0, 1, 45deg);
      }

      &::after {
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }

    &-variables {
      width: 26px;
      height: 26px;
      background: url("~application_v2/icon-variables-26x26.png") 0 0 no-repeat;

      @include retina {
        background-image: url("~application_v2/icon-variables-26x26@2x.png");
        background-size: 26px 26px;
      }
    }
  }
}

@media all and (min-resolution: 2dppx), all and (min-resolution: 144dpi) {
  .ico-flash {
    background: url("~application_v2/icon-flash-26x26-2x.png");
    background-size: 26px 26px;
  }

  .ico-cart {
    background: url("~application_v2/icon-cart-32x23-2x.png");
    background-size: 32px 23px;
  }

  .ico-star {
    background: url("~application_v2/icon-star-33x27-2x.png");
    background-size: 33px 27px;
  }

  .ico-speed {
    background: url("~application_v2/icon-speed-30x20-2x.png");
    background-size: 30px 20px;
  }

  .ico-switch {
    background: url("~application_v2/icon-switch-60x60-2x.png");
    background-size: 60px 60px;
  }

  .ico-schedule {
    background: url("~application_v2/icon-schedule-60x60-2x.png");
    background-size: 60px 60px;
  }

  .ico-arrow {
    background: transparent url("~application_v2/icon-arrows-16x26-2x.png");
    background-size: 16px 164px;
  }

  .ico-settings {
    background: url("~application_v2/icon-settings-60x44-2x.png");
    background-size: 60px 44px;
  }

  .ico-radar {
    background: url("~application_v2/icon-radar-60x60-2x.png");
    background-size: 60px 60px;
  }

  .ico-piechart {
    background: url("~application_v2/icon-piechart-60x60-2x.png");
    background-size: 60px 60px;
  }

  .ico-arrow-right {
    background: url("~static/icon-arrow-right-16x13-2x.png");
    background-size: 16px 13px;
  }

  .ico-link {
    background:
      url("~application_v2/icon-link-52x52@x2.png") no-repeat center
      center;
    background-size: 52px 52px;
  }

  .ico-copy {
    background: url("~application_v2/icon-copy-14x16@2x.png");
    background-size: 14px 16px;
  }

  .icon {
    &.icon {
      &-caution {
        background-image: url("~application_v2/icon-caution@2x.png");
        background-size: 22px 19px;
      }

      &-caution-circle {
        background-image: url("~application_v2/icon-caution-circle@2x.png");
        background-size: 16px 16px;
      }

      &-info-blue {
        background-image: url("~application_v2/icon-info-blue@2x.png");
        background-size: 16px 16px;
      }

      &-question {
        background-image: url("~application_v2/icon-circle-question-16x16@2x.png");
        background-size: 16px 16px;
      }

      &-search {
        background-image: url("~application_v2/icon-search-white-21x22@2x.png");
        background-size: 21px 22px;
      }

      &-paper-plane-firm {
        background-image: url("~application_v2/icon-paper-plane-firm-39x39@2x.png");
        background-size: 39px 39px;
      }

      &-dollar-dotted-circle-firm {
        background-image: url("~application_v2/icon-dollar-dotted-circle-firm-45x45@2x.png");
        background-size: 45px 45px;
      }

      &-heart-firm {
        background-image: url("~application_v2/icon-heart-firm-49x39@2x.png");
        background-size: 49px 39px;
      }

      &-cash-firm {
        background-image: url("~application_v2/icon-cash-firm-49x37@2x.png");
        background-size: 49px 37px;
      }

      &-circle-profile {
        background-image: url("~application_v2/icon-circle-profile-24x24@2x.png");
        background-size: 24px 24px;
      }

      &-small-star {
        background-image: url("~application_v2/icon-star-small-13x13@2x.png");
        background-size: 13px 13px;
      }

      &-small-check-mark {
        background-image: url("~application_v2/icon-small-green-check-mark-12x9@2x.png");
        background-size: 12px 9px;
      }

      &-white-x {
        background-image: url("~application_v2/icon-white-x-remove-15x15@2x.png");
        background-size: 15px 15px;
      }

      &-white-ok {
        background-image: url("~application_v2/icon-white-ok-check-20x15@2x.png");
        background-size: 20px 15px;
      }
    }
  }
}
