// Modal component
// ---------------
.modal {
  background-color: $inverse;
  box-shadow: 0 2px 8px rgba($base, 0.2), 0 0 0 10000px rgba($base, 0.3);
  color: $base;
  left: 50%;
  opacity: 0;
  padding: 30px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  visibility: hidden;
  width: 480px;
  z-index: 8888;

  @include mq-max-width(767px) {
    padding: 20px;
    width: calc(100% - 20px);
  }

  &.is-shown {
    opacity: 1;
    visibility: visible;
  }

  &-overlay {
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 8887;

    &.is-shown {
      display: block;
    }
  }

  &-title {
    font-size: 32px;
    margin-bottom: 8px;

    @include mq-max-width(767px) {
      font-size: 20px;
    }
  }

  &-content {
    font-size: 18px;
    margin-bottom: 16px;

    @include mq-max-width(767px) {
      font-size: 14px;
    }
  }

  &-close {
    cursor: pointer;
    height: 40px;
    opacity: 0.5;
    position: absolute;
    right: 10px;
    top: 8px;
    transition: opacity 0.3s;
    width: 40px;

    &:hover {
      opacity: 1;
    }

    &::before,
    &::after {
      background: $base;
      content: "";
      display: block;
      height: 32px;
      left: 50%;
      margin-top: -15px;
      position: absolute;
      top: 50%;
      width: 2px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(135deg);
    }
  }
}
