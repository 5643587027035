.form {
  &-wrapper {
    min-height: 355px;
  }

  &-text,
  &-textarea {
    background-color: $inverse;
    border: 1px solid rgba($base, 0.5);
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba($base, 0.07);
    box-sizing: border-box;
    display: block;
    height: 40px;
    line-height: normal;
    padding: 0 15px;
    width: 100%;
    appearance: none;

    &:focus {
      border-color: $base;
    }
  }

  &-textarea {
    height: auto;
    padding-bottom: 10px;
    padding-top: 10px;
    resize: vertical;
  }

  &-label {
    display: block;
  }

  .has-error {
    border-color: #ff4e4e;
  }

  @include mq-max-width(560px) {
    &.is-sales-form {
      .col1of2 {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;

        .mrm,
        .mlm {
          margin: 0;
        }
      }
    }
  }

  &-select {
    background-color: $inverse;
    border: 1px solid rgba($base, 0.5);
    border-radius: 4px;
    box-shadow: inset 0 -5px 5px -5px rgba($base, 0.1);
    display: block;
    min-width: 180px;
    position: relative;
    margin: 0 auto;
    z-index: 10;

    &::after {
      border-left: 1px solid rgba($base, 0.2);
      color: $base;
      content: "∨";
      font-size: 18px;
      height: 100%;
      line-height: 36px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 40px;
      z-index: -1;
    }

    select {
      appearance: none;
      background: transparent;
      border: none;
      border-radius: 0;
      color: $base;
      display: block;
      font-size: 14px;
      margin: 0;
      outline: none;
      padding: 9px 55px 10px 15px;
      width: 100%;
    }
  }
}
