.sticky-subnav {
  background-color: #fff;
  left: 50%;
  margin-left: -50%;
  padding: 18px 0 19px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  transition: 0.3s;

  &.is-merged {
    border-radius: 0 0 6px 6px;
    left: 50%;
    margin-left: -140px;
    position: fixed;
    padding: 7px 0;
    top: 52px;
    z-index: 13;
    width: 280px;
    box-shadow: 0 2px 5px rgba(#000, 0.15);

    a {
      &.is-active {
        &::after {
          bottom: -11px;
        }
      }
    }
  }

  ul {
    font-size: 0;
    vertical-align: middle;
    display: inline-block;
  }

  li {
    display: inline-block;
    vertical-align: middle;
    padding: 0 20px;
    border-left: 1px solid #cac0bc;

    &:first-child {
      border-left: none;
    }
  }

  a {
    color: #244aa1;
    font-family: $gotham-medium;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    position: relative;
    text-transform: uppercase;

    &:hover {
      color: $firm;
    }

    &.is-active {
      &::after {
        background-color: $firm;
        bottom: -23px;
        content: "";
        display: block;
        height: 2px;
        position: absolute;
        width: 100%;
        transition: bottom 0.3s;
        backface-visibility: hidden;
      }
    }
  }

  &-holder {
    height: 64px;
  }

  &-offset {
    position: relative;
    top: -64px;
  }
}
