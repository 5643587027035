@import "stylesheets/application_v2/fonts_config";

@mixin mobile {
  @media all and (width <= 769px) {
    @content;
  }
}

.header {
  .only-desktop {
    @include mobile {
      display: none;
    }
  }

  .Static-container {
    box-sizing: border-box;
    transition:
      width 0.5s ease,
      padding 0.3s ease;
  }

  @include mobile {
    height: auto;
    padding-top: 15px;
  }

  &.is-static {
    position: static;
  }

  &.is-inverse:not(.is-sticky) {
    .header-logo {
      g > g > * {
        fill: $inverse;
      }
    }

    .header-nav-link,
    .header-link-book-demo-mobile {
      color: $inverse;

      &:hover,
      &:focus {
        color: $firm;
      }
    }

    .header-burger {
      svg path {
        fill: $inverse;
      }
    }

    .header-nav-link {
      border-color: rgba($inverse, 0.5);

      &:hover,
      &:focus {
        border-color: $firm;
      }
    }
  }

  &.is-sticky {
    background-color: #fff;
    border-bottom: 1px solid rgba($base, 0.1);
    padding-bottom: 16px;
    padding-top: 16px;
    position: fixed;

    .burger {
      margin-top: 0;
      padding: 15px 16px 13px;
    }

    .header-login-btn {
      box-shadow: none;
    }
  }

  &.is-wide {
    .Static-container {
      padding-left: 30px;
      padding-right: 30px;
      width: 100%;
    }
  }

  &.is-barebone {
    &:not(.is-sticky) {
      .header-nav-container,
      .burger {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  & {
    padding-top: 24px;
    position: fixed;
    top: 0;
    transition: 0.5s;
    width: 100%;
    z-index: 1001;
  }
}

.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header-container-side {
  display: flex;
}

.header-logo {
  align-items: center;
  display: flex;
  transition: 0.3s;
  width: 122px;
  margin-right: 24px;

  @include mq-max-width(640px) {
    width: 100px;
  }

  @include mq-max-width(375px) {
    width: 76px;
  }

  svg {
    width: 100%;
  }
}

.header-nav-container {
  display: flex;
  font-size: 16px;
  transition:
    padding 0.25s ease,
    opacity 0.5s ease;
}

.header-nav-cell {
  margin-left: 24px;
  margin-right: 24px;
  position: relative;

  @include mq-max-width(1024px) {
    margin-left: 16px;
    margin-right: 16px;
  }

  @include mq-max-width(769px) {
    font-size: 14px;
  }
}

.header-grid {
  align-items: center;
  display: flex;
}

.header-burger {
  @include mobile {
    display: inline-flex;
  }

  & {
    background: transparent;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    outline: 0;
    display: none;
  }

  .hero-close-icon {
    display: none;

    path {
      fill: #657389 !important;
    }
  }

  .hero-burger-icon {
    path {
      fill: #657389;
    }
  }

  &.is-open {
    margin: 0;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 9999;

    .hero-burger-icon {
      display: none;
    }

    .hero-close-icon {
      display: block;
    }
  }
}

.header-link {
  color: #1c2940;
  font-family: $aktiv-grotesk;
  letter-spacing: -0.5px;
}

.header-link-book-demo-mobile {
  display: inline-block;
  transform: translateY(1px);
}

.header-login-btn {
  @extend .button;

  background: #fff;
  display: flex;
  margin-right: 16px;
  margin-left: 20px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-family: $aktiv-grotesk;
  color: #1c2940 !important;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.3px;
  box-shadow:
    0 1px 1px rgb(28 41 64 / 10%),
    0 2px 4px rgb(28 41 64 / 10%);

  &:hover {
    background: #fff;
    box-shadow:
      0 1px 1px rgb(28 41 64 / 10%),
      0 2px 4px rgb(28 41 64 / 10%) !important;
  }

  &:focus {
    background: #fff;
    box-shadow:
      0 1px 1px rgb(28 41 64 / 10%),
      0 2px 4px rgb(28 41 64 / 10%) !important;
  }

  &:active {
    background: #fff;
  }
}

.header-mobile-block {
  display: none;

  @include mobile {
    display: block;
  }
}

.header-mobile-menu {
  text-align: left;

  .Static-container {
    position: relative;
    height: 100%;
  }

  &.is-open {
    display: block;
  }

  & {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    overflow: auto;
  }
}

.header-mobile-menu-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-mobile-menu-logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  margin-top: 24px;
  position: relative;
}

.header-mobile-menu-logo {
  width: 140px;
}

.header-mobile-menu-nav-item {
  margin-bottom: 16px;
}

.header-mobile-menu-nav-link {
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
}

.header-mobile-menu-footer {
  margin-top: 64px;
  width: 100%;

  @media all and (height <= 470px) {
    margin-top: 24px;
  }
}

.header-mobile-menu-footer-link {
  display: inline-block;
  width: 100%;
  font-size: 16px !important;
  text-align: center;
  padding: 8px !important;
  margin: 0 0 16px;
}
